import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import { useAccount } from 'wagmi';
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { CustomConnectButton } from "../components/CustomConnectButton";

import { PresaleContext } from "../context/presale-context";
import { ADMIN_ADDRESS } from "../config";

const Header = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const presaleContext = useContext(PresaleContext);

    const { address } = useAccount();

    const [showMobileMenu, setShowMobileMenu] = useState(false);

    useEffect(() => {
        if (location.search.includes("?ref=")) {
            presaleContext.setReferrer(location.search.replace("?ref=", ""));
        }
    }, [location])

    return (
        <>
            <div className="font-helvetica absolute top-0 left-0 w-[100vw] h-[160px] flex gap-[20px] md:gap-[40px] justify-between items-center px-[10px] md:px-[60px] lg:px-[120px] z-40">
                <span onClick={(e) => { navigate(`/${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className="text-[50px] font-bold text-[#000] cursor-pointer">Schöpf</span>
                <div className="hidden lg:flex gap-[80px] items-center">
                    <div className="flex gap-[80px]">
                        <button onClick={(e) => { navigate(`/buynow${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[14px] ${location.pathname == '/buynow' ? 'font-bold' : ''} text-[#000]`}>Buynow</button>
                        <button onClick={(e) => { navigate(`/dashboard${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[14px] ${location.pathname == '/dashboard' ? 'font-bold' : ''} text-[#000]`}>Dashboard</button>
                        <button onClick={(e) => { navigate(`/referral${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[14px] ${location.pathname == '/referral' ? 'font-bold' : ''} text-[#000]`}>Referral</button>
                        {address && address.toLowerCase() == ADMIN_ADDRESS.toLowerCase() && <button onClick={(e) => { navigate(`/icoadmin${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[14px] ${location.pathname == '/icoadmin' ? 'font-bold' : ''} text-[#000]`}>Ico Admin</button>}
                    </div>
                    <CustomConnectButton />
                </div>
                <button onClick={(e) => { setShowMobileMenu(true); }} className="flex lg:hidden">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                        <path data-name="Path 205" d="M0 0h32v32H0z" fill="none" />
                        <path data-name="Path 206" d="M3 22.852h24.074v-2.809H3zm0-7.022h24.074v-2.808H3zM3 6v2.809h24.074V6z" transform="translate(.963 1.574)" fill="#000" />
                    </svg>
                </button>
            </div >
            {showMobileMenu &&
                <div className={`font-patrick fixed top-0 left-0 w-[100vw] h-[100vh] flex justify-end items-start bg-[#ffffff30] backdrop-blur-md p-[40px] z-50`}>
                    <div
                        className="fixed inset-0 transition-opacity"
                        onClick={() => { setShowMobileMenu(false); }}
                    />
                    <div className="bg-[#00000030] backdrop-blur-sm flex flex-col border-2 border-white rounded-[10px] py-[20px] px-[10px]" >
                        <button onClick={(e) => { setShowMobileMenu(false); navigate(`/buynow${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[20px] ${location.pathname == '/buynow' ? 'font-bold' : ''} text-[#000] border-b border-white py-[10px]`}>Buynow</button>
                        <button onClick={(e) => { setShowMobileMenu(false); navigate(`/dashboard${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[20px] ${location.pathname == '/dashboard' ? 'font-bold' : ''} text-[#000] border-b border-white py-[10px]`}>Dashboard</button>
                        <button onClick={(e) => { setShowMobileMenu(false); navigate(`/referral${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[20px] ${location.pathname == '/referral' ? 'font-bold' : ''} text-[#000] border-b border-white py-[10px]`}>Referral</button>
                        {address && address.toLowerCase() == ADMIN_ADDRESS.toLowerCase() && <button onClick={(e) => { setShowMobileMenu(false); navigate(`/icoadmin${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[20px] ${location.pathname == '/icoadmin' ? 'font-bold' : ''} text-[#000] border-b border-white py-[10px]`}>Ico Admin</button>}
                        <div className="flex justify-center py-[10px]">
                            <CustomConnectButton />
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Header;