import { createContext, useEffect, useState } from "react";
import apollo from "../lib/apolloClient";

import { useAccount, useContractRead, useContractWrite, useContractReads, useWalletClient } from 'wagmi';

import schAbi from "../abis/sch_abi.json";
import saleAbi from "../abis/sale_abi.json";
import presaleAbi from "../abis/presale_abi.json";
// import { ADMIN_ADDRESS, SEPOLIA_SCHOPF_PRESALE_ADDRESS, claimHistoryQuery } from "../config";
import { ADMIN_ADDRESS, MAINNET_SCHOPF_PRESALE_ADDRESS, claimHistoryQuery } from "../config";
import { mainnet } from "viem/chains";

export const presaleAddress = MAINNET_SCHOPF_PRESALE_ADDRESS;

function usePresaleContext() {
    const { address } = useAccount();

    const [referrer, setReferrer] = useState("");
    const [claimHistory, setClaimHistory] = useState([]);

    const { data: walletClient } = useWalletClient({ chainId: mainnet.id });

    const {
        isLoading: isLoadingRoundCount,
        data: roundCount,
        refetch: refetchRoundCount,
    } = useContractRead({
        abi: presaleAbi,
        address: presaleAddress,
        functionName: 'getRoundCount',
        args: [],
        cacheTime: 1000 * 60 * 60,
        select: (data) => Number(data)
    })

    const {
        isLoading: isLoadingSchAddress,
        data: schAddress,
    } = useContractRead({
        abi: presaleAbi,
        address: presaleAddress,
        functionName: 'schAddress',
        args: [],
        cacheTime: 1000 * 60 * 60,
    })

    const {
        isLoading: isLoadingSaleAddress,
        data: saleAddress,
    } = useContractRead({
        abi: presaleAbi,
        address: presaleAddress,
        functionName: 'saleAddress',
        args: [],
        cacheTime: 1000 * 60 * 60
    })

    const {
        isLoading: isLoadingStages,
        data: stages,
        refetch: refetchStages,
    } = useContractReads({
        contracts: Array.from(new Array(roundCount)).map((_, i) => ({
            address: presaleAddress,
            abi: presaleAbi,
            functionName: 'stages',
            args: [i]
        })),
        select: (data) => data.map(({ result }) => ({
            timeToStart: Number(result?.[0]),
            timeToEnd: Number(result?.[1]),
            timeToClaim: Number(result?.[2]),
            minAmount: Number(result?.[3]),
            amount: Number(result?.[4]),
            affiliateSale: Number(result?.[5]),
            totalSale: Number(result?.[6]),
            price: Number(result?.[7]),
            affiliateFee: Number(result?.[8]),
            vestingPeriod: Number(result?.[9]),
            affiliateCount: Number(result?.[10])
        })),
        enabled: Boolean(roundCount) && roundCount != 0
    })

    const {
        isLoading: isLoadingUserDeposited,
        data: userDeposited,
        refetch: refetchUserDeposited
    } = useContractReads({
        contracts: Array.from(new Array(roundCount)).map((_, i) => ({
            address: presaleAddress,
            abi: presaleAbi,
            functionName: 'userDeposited',
            args: [i, address]
        })),
        enabled: Boolean(roundCount) && roundCount != 0 && Boolean(address),
        select: (data) => data.map(({ result }) => (
            Number(result)
        )),
    })

    const {
        isLoading: isLoadingUserClaimed,
        data: userClaimed,
        refetch: refetchUserClaimed
    } = useContractReads({
        contracts: Array.from(new Array(roundCount)).map((_, i) => ({
            address: presaleAddress,
            abi: presaleAbi,
            functionName: 'userClaimed',
            args: [i, address]
        })),
        enabled: Boolean(roundCount) && roundCount != 0 && Boolean(address),
        select: (data) => data.map(({ result }) => (
            Number(result)
        )),
    })

    const {
        isLoading: isLoadingUserLastClaimed,
        data: userLastClaimed,
        refetch: refetchUserLastClaimed
    } = useContractReads({
        contracts: Array.from(new Array(roundCount)).map((_, i) => ({
            address: presaleAddress,
            abi: presaleAbi,
            functionName: 'userLastClaimed',
            args: [i, address]
        })),
        enabled: Boolean(roundCount) && roundCount != 0 && Boolean(address),
        select: (data) => data.map(({ result }) => (
            Number(result)
        )),
    })

    const {
        isLoading: isLoadingAllowance,
        data: allowance,
        refetch: refetchAllowance
    } = useContractRead({
        abi: saleAbi,
        address: saleAddress,
        functionName: 'allowance',
        args: [address, presaleAddress],
        enabled: Boolean(address) && Boolean(presaleAddress) && Boolean(saleAddress),
        select: (data) => Number(data)
    })

    const {
        isLoading: isLoadingBalance,
        data: balance,
        refetch: refetchBalance
    } = useContractRead({
        abi: saleAbi,
        address: saleAddress,
        functionName: 'balanceOf',
        args: [address],
        enabled: Boolean(address) && Boolean(saleAddress),
        select: (data) => Number(data)
    })

    const {
        isLoading: isLoadingPresaleSchBalance,
        data: presaleSchBalance,
        refetch: refetchPresaleSchBalance
    } = useContractRead({
        abi: schAbi,
        address: schAddress,
        functionName: 'balanceOf',
        args: [presaleAddress],
        enabled: Boolean(address) && Boolean(schAddress) && address && address.toLowerCase() == ADMIN_ADDRESS.toLowerCase(),
        select: (data) => Number(data)
    })

    const {
        isLoading: isLoadingPresaleSaleBalance,
        data: presaleSaleBalance,
        refetch: refetchPresaleSaleBalance
    } = useContractRead({
        abi: saleAbi,
        address: saleAddress,
        functionName: 'balanceOf',
        args: [presaleAddress],
        enabled: Boolean(address) && Boolean(saleAddress) && address && address.toLowerCase() == ADMIN_ADDRESS.toLowerCase(),
        select: (data) => Number(data)
    })

    const isLoading = isLoadingRoundCount || isLoadingSchAddress || isLoadingSaleAddress || isLoadingStages || isLoadingUserDeposited || isLoadingUserClaimed || isLoadingUserLastClaimed || isLoadingAllowance || isLoadingBalance || isLoadingPresaleSchBalance || isLoadingPresaleSaleBalance

    const executeDeposit = async (args, from) => {
        const result = await walletClient?.writeContract({
            abi: presaleAbi,
            address: presaleAddress,
            functionName: 'deposit',
            args,
            from
        })
        return result;
    }

    // const { writeAsync: executeDeposit } = useContractWrite({
    //     abi: presaleAbi,
    //     address: presaleAddress,
    //     functionName: 'deposit',
    // })

    const executeAffiliateClaim = async (args, from) => {
        const result = await walletClient?.writeContract({
            abi: presaleAbi,
            address: presaleAddress,
            functionName: 'claimAffiliateReward',
            args,
            from
        })
        return result;
    }

    const executeClaim = async (args, from) => {
        const result = await walletClient?.writeContract({
            abi: presaleAbi,
            address: presaleAddress,
            functionName: 'claim',
            args,
            from
        })
        return result;
    }

    // const { writeAsync: executeClaim } = useContractWrite({
    //     abi: presaleAbi,
    //     address: presaleAddress,
    //     functionName: 'claim',
    // })

    const executeApprove = async (args, from) => {
        const result = await walletClient?.writeContract({
            abi: saleAbi,
            address: saleAddress,
            functionName: 'approve',
            args,
            from,
        })
        return result;
    }

    // const { writeAsync: executeApprove } = useContractWrite({
    //     abi: saleAbi,
    //     address: saleAddress,
    //     functionName: 'approve',
    // })

    const executeWithdraw = async (args, from) => {
        const result = await walletClient?.writeContract({
            abi: presaleAbi,
            address: presaleAddress,
            functionName: 'RescueFunds',
            args,
            from,
        })
        return result;
    }

    // const { writeAsync: executeWithdraw } = useContractWrite({
    //     abi: presaleAbi,
    //     address: presaleAddress,
    //     functionName: 'RescueFunds',
    // })

    const executeCreateRound = async (args, from) => {
        const result = await walletClient?.writeContract({
            abi: presaleAbi,
            address: presaleAddress,
            functionName: 'createRound',
            args,
            from,
        })
        return result;
    }

    // const { writeAsync: executeCreateRound } = useContractWrite({
    //     abi: presaleAbi,
    //     address: presaleAddress,
    //     functionName: 'createRound',
    // })

    const fetchClaimHistory = () => {
        apollo(claimHistoryQuery).then((r) => {
            let _claimsHistory = r.data.claims;
            if (_claimsHistory.length != claimHistory.length) {
                setClaimHistory(_claimsHistory);
            }
        });
    };

    useEffect(() => {

        fetchClaimHistory();

        const timerID = setInterval(() => {
            refetchRoundCount();

            if (roundCount != 0) {
                refetchStages();
                if (address) {
                    refetchUserDeposited();
                    refetchUserClaimed();
                    refetchUserLastClaimed();
                }
            }

            if (address) {
                if (saleAddress) {
                    refetchAllowance();
                    refetchBalance();
                    refetchPresaleSaleBalance();
                }
                if (schAddress) {
                    refetchPresaleSchBalance();
                }
            }

        }, 1000 * 3);

        return () => {
            clearInterval(timerID);
        };
    }, []);

    useEffect(() => {
        if (roundCount != 0) {
            refetchStages();
            if (address) {
                refetchUserDeposited();
                refetchUserClaimed();
                refetchUserLastClaimed();
            }
        }
        if (address) {
            if (saleAddress) {
                refetchAllowance();
                refetchBalance();
                refetchPresaleSaleBalance();
            }
            if (schAddress) {
                refetchPresaleSchBalance();
            }
        }
    }, [roundCount, address])

    return {
        referrer,
        setReferrer,
        roundCount,
        schAddress,
        saleAddress,
        stages: roundCount == 0 ? [] : stages,
        userDeposited,
        userClaimed,
        userLastClaimed,
        allowance,
        balance,
        presaleSchBalance,
        presaleSaleBalance,
        isLoading,
        refetchAllowance,
        refetchBalance,
        refetchPresaleSchBalance,
        refetchPresaleSaleBalance,
        executeDeposit,
        executeAffiliateClaim,
        executeClaim,
        executeApprove,
        executeWithdraw,
        executeCreateRound,
        claimHistory
    };
};

export const PresaleContext = createContext();

export const PresaleProvider = (props) => {
    const presaleContext = usePresaleContext();

    return (
        <div>
            <PresaleContext.Provider value={{ ...presaleContext }}>
                {props.children}
            </PresaleContext.Provider>
        </div>
    );
};