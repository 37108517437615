import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { useAccount } from 'wagmi';
import { waitForTransaction, readContracts } from "@wagmi/core";
import { parseEther, formatUnits } from 'viem';
import { PresaleContext } from "../context/presale-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-toastify";
import presaleAbi from "../abis/presale_abi.json";
// import { SEPOLIA_SCHOPF_PRESALE_ADDRESS } from "../config";
import { MAINNET_SCHOPF_PRESALE_ADDRESS } from "../config";
export const presaleAddress = MAINNET_SCHOPF_PRESALE_ADDRESS;

const Referral = () => {

    const navigate = useNavigate();

    const presaleContext = useContext(PresaleContext);

    const { address } = useAccount();

    const [referralBonus, setReferralBonus] = useState(0);
    const [referralInfo, setReferralInfo] = useState([]);

    const [searchWord, setSearchWord] = useState("");
    const [claimPending, setClaimPending] = useState(false);

    useEffect(() => {
        if (!address) {
            toast.error("Connect Wallet First!");
            navigate(`/${presaleContext.referral ? `?ref=${presaleContext.referral}` : ""}`)
        }
    }, [address])

    const fetchAffiliateList = async () => {
        const affiliateCount = presaleContext.stages[presaleContext.roundCount - 1].affiliateCount;
        let contracts = [];
        for (let i = 0; i < affiliateCount; i++) {
            contracts.push({
                address: presaleAddress,
                abi: presaleAbi,
                functionName: 'affiliateList',
                args: [presaleContext.roundCount - 1, i]
            })
        }
        const affiliateList = await readContracts({ contracts });

        console.log(affiliateList);
        contracts = [];

        for (let i = 0; i < affiliateCount; i++) {
            contracts.push({
                address: presaleAddress,
                abi: presaleAbi,
                functionName: 'affiliates',
                args: [affiliateList[i].result[0], affiliateList[i].result[1]]
            })
        }
        const affiliateListResult = await readContracts({ contracts });
        console.log(affiliateListResult);

        let referralBonus = 0;
        const referralInfo = affiliateList.map((item, index) => {
            const otherItem = affiliateListResult[index];
            console.log('otherItem: ', otherItem.result[2]);
            if (item.result?.[0] == address && otherItem.result[2] == false) {
                console.log('item.result: ', item.result?.[0]);
                referralBonus += Number(item.result?.[2]);
                return ({
                    referralAddress: item.result?.[0],
                    investorAddress: item.result?.[1],
                    bonus: Number(item.result?.[2]),
                    dateTime: Number(item.result?.[3])
                });
            }
            return null;
        })
        .filter(Boolean);

        console.log('referralInfo: ',referralInfo);

        setReferralInfo(referralInfo);
        setReferralBonus(referralBonus);

    }

    const onAffiliateClaim = async (refAddr) => {
        setClaimPending(true);
        try {
            if (address) {
                if (presaleContext.roundCount != 0) {
                    const resultClaim = await presaleContext.executeAffiliateClaim(
                        [refAddr],
                        address
                    );
                    const txData = await waitForTransaction({ hash: resultClaim });
                    if (txData && txData.status === "success") {
                        toast.success("AffiliateClaim successfully");
                    } else {
                        toast.error("Error! AffiliateClaiming is failed.");
                    }
                } else {
                    toast.error("No round!");
                }
            } else {
                toast.error("Connect Wallet First!");
            }
        } catch (error) {
            toast.error(error.message);
        }
        setClaimPending(false);
    }

    useEffect(() => {

        if (!presaleContext.isLoading && presaleContext.stages.length != 0 && presaleContext.roundCount != 0) {
            fetchAffiliateList();
        }
    }, [presaleContext])

    return (
        <div className="font-helvetica relative w-[100vw] flex flex-col bg-[#fff] ">
            <div className="lg:h-[100vh] flex flex-col gap-[40px] items-center pt-[160px] px-[40px] lg:px-[120px] pb-[40px]">
                <div className="w-full flex flex-col lg:flex-row gap-[40px] justify-between">
                    <div className="w-full flex flex-col gap-[20px] justify-center items-center bg-[#e6e7e9] rounded-md shadow-sm px-[30px] py-[60px]">
                        <span className="text-[20px] lg:text-[30px] font-bold text-[#000]">Referral Bonus</span>
                        <span className="text-[20px] lg:text-[30px] font-bold text-[#000]">{referralBonus / 1000000} USDT</span>
                        
                    </div>
                </div>
                <div className="flex-1 w-full flex flex-col lg:flex-row gap-[40px] justify-between">
                    <div className="w-full flex flex-col gap-[10px] justify-center items-center bg-[#e6e7e9] rounded-md shadow-sm px-[30px] py-[30px]">
                        <div className="w-full flex flex-col lg:flex-row gap-[10px] justify-between lg:items-center">
                            <span className="text-[20px] lg:text-[30px] font-bold text-[#000]">Referrals</span>
                            <input onChange={(e) => setSearchWord(e.target.value)} value={searchWord} placeholder="SEARCH" className="text-[14px] text-[#000] px-[10px] py-[5px] bg-[#fff] focus:outline-none rounded-md" />
                        </div>
                        <div className="flex-1 w-full hidden lg:flex flex-col">
                            <div className="flex flex-col lg:flex-row items-start lg:items-center py-[10px] pb-[10px] border-b border-b-[#000]">
                                <div className="w-full flex items-center">
                                    <span className="w-4/12 text-[14px] font-bold text-[#000]">No</span>
                                    <span className="w-8/12 text-[14px] font-bold text-[#000]">Referral Address</span>
                                </div>
                                <div className="w-full flex items-center">
                                    <span className="w-8/12 text-[14px] font-bold text-[#000]">Investor Address</span>
                                    <span className="w-4/12 text-[14px] font-bold text-[#000]">Bonus</span>
                                </div>
                                <div className="w-full flex items-center">
                                    <span className="w-full text-[14px] font-bold text-[#000]">Date & Time</span>
                                    <span className="w-[150px] text-[14px] font-bold text-[#000]">Claim</span>
                                </div>
                            </div>
                            <div className="max-h-[300px] overflow-y-auto w-full flex flex-col">
                                {referralInfo.map((referral, index) => {
                                    return (
                                        (!searchWord || referral?.investorAddress?.includes(searchWord)) && (
                                            <div key={index} className="flex flex-col lg:flex-row items-start lg:items-center py-[10px] pb-[10px] border-b border-b-[#000]">
                                                <div className="w-full flex items-center">
                                                    <span className="w-4/12 text-[14px] text-[#000]">{index + 1}</span>
                                                    <span className="w-8/12 text-[14px] text-[#000]">{referral?.referralAddress?.slice(0, 4)}...{referral?.referralAddress?.slice(referral.referralAddress.length - 4)}</span>
                                                </div>
                                                <div className="w-full flex items-center">
                                                    <span className="w-8/12 text-[14px] text-[#000]">{referral?.investorAddress?.slice(0, 4)}...{referral?.investorAddress?.slice(referral.investorAddress.length - 4)}</span>
                                                    <div className="w-4/12 flex gap-[10px] items-center">
                                                        <span className="text-[14px] text-[#000]">{referral?.bonus / 1000000}</span>
                                                        <img src="/images/tether.png" alt="" className="w-[16px] h-[16px] object-contain" />
                                                    </div>
                                                </div>
                                                <div className="w-full flex items-center">
                                                    <span className="w-full text-[14px] text-[#000]">{(new Date(referral?.dateTime * 1000)).toLocaleString()}</span>
                                                    <span className="w-[150px]">
                                                        <button onClick={(e) => onAffiliateClaim(referral?.investorAddress)} className="text-[20px] font-bold text-[#fff] bg-[#00f] px-[20px] py-[5px] rounded-md shadow-sm">Claim {claimPending ? <FontAwesomeIcon icon={faSpinner} size="sm" className="animate-spin" /> : <></>}</button>
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    );
                                })}
                            </div>
                        </div>
                        <div className="w-full flex lg:hidden overflow-x-auto">
                            <div className="flex flex-col">
                                <div className="flex py-[10px] pb-[10px] border-b border-b-[#000]">
                                    <span className="w-[100px] text-[14px] font-bold text-[#000]">No</span>
                                    <span className="w-[300px] text-[14px] font-bold text-[#000]">Referral Address</span>
                                    <span className="w-[300px] text-[14px] font-bold text-[#000]">Investor Address</span>
                                    <span className="w-[100px] text-[14px] font-bold text-[#000]">Bonus</span>
                                    <span className="w-[300px] text-[14px] font-bold text-[#000]">Date & Time</span>
                                    <span className="w-[150px] text-[14px] font-bold text-[#000]">Claim</span>
                                </div>
                                <div className="max-h-[300px] overflow-y-auto flex flex-col">
                                    {referralInfo.map((referral, index) => {
                                        if(referral?.referralAddress && referral?.investorAddress) {
                                            return (
                                                <div key={index} className="flex flex-row items-start lg:items-center py-[10px] pb-[10px] border-b border-b-[#000]">
                                                    <span className="w-[100px] text-[14px] text-[#000]">{index + 1}</span>
                                                    <span className="w-[300px] text-[14px] text-[#000]">{referral.referralAddress.slice(0, 4)}...{referral.referralAddress.slice(referral.referralAddress.length - 4)}</span>
                                                    <span className="w-[300px] text-[14px] text-[#000]">{referral.investorAddress.slice(0, 4)}...{referral.investorAddress.slice(referral.investorAddress.length - 4)}</span>
                                                    <div className="w-[100px] flex gap-[10px] items-center">
                                                        <span className="text-[14px] text-[#000]">{referral.bonus / 1000000}</span>
                                                        <img src="/images/tether.png" alt="" className="w-[16px] h-[16px] object-contain" />
                                                    </div>
                                                    <span className="w-[300px] text-[14px] text-[#000]">{(new Date(referral.dateTime * 1000).toLocaleString())}</span>
                                                    <span className="w-[150px]">
                                                        <button onClick={(e) => onAffiliateClaim(referral.investorAddress)} className="text-[20px] font-bold text-[#fff] bg-[#00f] px-[20px] py-[5px] rounded-md shadow-sm">Claim {claimPending ? <FontAwesomeIcon icon={faSpinner} size="sm" className="animate-spin" /> : <></>}</button>
                                                    </span>
                                                </div>
                                            );    
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Referral;