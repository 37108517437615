import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";

import NoPage from "./pages/NoPage";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Buynow from "./pages/Buynow";
import Dashboard from "./pages/Dashboard";
import Referral from "./pages/Referral";
import Admin from "./pages/Admin";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { mainnet, sepolia } from 'viem/chains';
import { publicProvider } from "wagmi/providers/public";
import { infuraProvider } from "wagmi/providers/infura";

import { connectorsForWallets, RainbowKitProvider, lightTheme } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { metaMaskWallet, coinbaseWallet } from "@rainbow-me/rainbowkit/wallets";

import { PresaleProvider } from "./context/presale-context";

let chain = mainnet;

chain = {
  id: chain.id,
  name: chain.name,
  nativeCurrency: chain.nativeCurrency,
  rpcUrls: {
    infura: chain.rpcUrls.infura,
    // alchemy: {
    //   http: ['https://eth-mainnet.g.alchemy.com/v2'],
    //   webSocket: ['wss://eth-mainnet.g.alchemy.com/v2'],
    // },
    public: {
      http: ['https://black-methodical-pine.quiknode.pro/7c0fa9ec375a6c94ed6ac95d7cd35b92fc3387c1'],
      webSocket: ['wss://black-methodical-pine.quiknode.pro/7c0fa9ec375a6c94ed6ac95d7cd35b92fc3387c1'],
    },
  },
  blockExplorers: chain.blockExplorers,
  contracts: chain.contracts,
}

const customProvider = chain.rpcUrls.public?.http?.[0] || chain.rpcUrls.infura.http?.[0];
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [chain],
  [
    infuraProvider({ apiKey: '320af4b9cfda4e8bbc3e361d8d8c0e68' }),
    publicProvider({ rpc: () => customProvider }),
  ]
  // [publicProvider()],
  // [alchemyProvider({ apiKey: 'overqCbGuiooR0qPmXq-40mLufAC9frC' })]
)

const walletsOptions = { chains: chains, projectId: "dj0mnc1dya3s9zuz" };
const wallets = [
  metaMaskWallet({ ...walletsOptions, shimDisconnect: true }),
  // coinbaseWallet({ ...walletsOptions, appName: "schopf" }),
];

const wagmiConnectors = connectorsForWallets([
  {
    groupName: "Supported Wallets",
    wallets,
  },
]);

const config = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient,
  connectors: wagmiConnectors
})

export default function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="/buynow" element={<Layout />}>
          <Route index element={<Buynow />} />
          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="/dashboard" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="/referral" element={<Layout />}>
          <Route index element={<Referral />} />
          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="/icoadmin" element={<Layout />}>
          <Route index element={<Admin />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <WagmiConfig config={config}>
    <RainbowKitProvider
      chains={chains}
      modalSize="compact"
      theme={lightTheme()}
    >
      <PresaleProvider>
        <App />
      </PresaleProvider>
    </RainbowKitProvider>
  </WagmiConfig>
  , document.getElementById("root")
);
