import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { useAccount } from 'wagmi';
import { waitForTransaction } from "@wagmi/core";
import { parseEther, formatUnits } from 'viem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { PresaleContext } from "../context/presale-context";

import { toast } from "react-toastify";
// import { BASIC_URL, EMPTY_ADDRESS, SEPOLIA_SCHOPF_PRESALE_ADDRESS } from "../config";
import { BASIC_URL, EMPTY_ADDRESS, MAINNET_SCHOPF_PRESALE_ADDRESS } from "../config";

const predictionAddress = MAINNET_SCHOPF_PRESALE_ADDRESS;

const Buynow = () => {

    const navigate = useNavigate();

    const presaleContext = useContext(PresaleContext);

    const { address } = useAccount();

    const [balance, setBalance] = useState(0);
    const [minAmount, setMinAmount] = useState(1);
    const [depositedAmount, setDepositedAmount] = useState(0);
    const [saleSymbol, setSaleSymbol] = useState("USDT");
    const [schSymbol, setSchSymbol] = useState("SCH$");

    const [depositAmount, setDepositAmount] = useState("");

    const [approvePending, setApprovePending] = useState(false);
    const [depositPending, setDepositPending] = useState(false);
    const [claimPending, setClaimPending] = useState(false);
    const [tokenPrice, setTokenPrice] = useState(0);


    useEffect(() => {
        if (!address) {
            toast.error("Connect Wallet First!");
            navigate(`/${presaleContext.referral ? `?ref=${presaleContext.referral}` : ""}`)
        } else {
            if (!presaleContext.isLoading) {
                setBalance((presaleContext.balance ? presaleContext.balance : 0) / 1000000);

                setDepositedAmount(Number((presaleContext.userDeposited[presaleContext.roundCount - 1] ? presaleContext.userDeposited[presaleContext.roundCount - 1] : 0) / 1000000).toFixed(0));

                if (presaleContext.stages.length != 0 && presaleContext.roundCount != 0) {
                    const minAmount = presaleContext.stages[presaleContext.roundCount - 1].minAmount;
                    setMinAmount(minAmount ? minAmount : 1);
                }

                const tokenPrice = presaleContext.stages[presaleContext.roundCount - 1].price / 10000;
                setTokenPrice(tokenPrice);
            }
        }
    }, [address, presaleContext])

    // approve & deposit
    const onApproveAndDeposit = async () => {
        presaleContext.refetchBalance();
        // console.log("onApproveAndDeposit", address, Number(depositAmount), minAmount);
        setApprovePending(true);
        try {
            if (address) {
                if (Number(depositAmount) * 1000000 <= presaleContext.balance) {
                    if (Number(depositAmount) >= minAmount) {
                        // console.log("pre executeApprove", predictionAddress, Number(depositAmount * 1000000));

                        // const resultApprove = await presaleContext.executeApprove({
                        //     args: [predictionAddress, Number(depositAmount * 1000000)],
                        //     from: address
                        // });
                        const resultApprove = await presaleContext.executeApprove(
                            [predictionAddress, Number(depositAmount) * 1000000],
                            address
                        );
                        // console.log("resultApprove", resultApprove)
                        const txData = await waitForTransaction({ hash: resultApprove });
                        // console.log("resultApprove txData", txData)
                        if (txData && txData.status === "success") {
                            // console.log("executeApprove:", resultApprove);
                            toast.success("Approve successfully");
                            presaleContext.refetchAllowance();

                            const resultDeposit = await presaleContext.executeDeposit(
                                [presaleContext.roundCount - 1, depositAmount * 1000000, presaleContext.referrer ? presaleContext.referrer : EMPTY_ADDRESS],
                                address
                            );
                            // console.log("resultDeposit", resultDeposit)
                            const txData = await waitForTransaction({ hash: resultDeposit });
                            // console.log("resultDeposit txData", txData)
                            if (txData && txData.status === "success") {
                                // console.log("executeDeposit:", resultDeposit);
                                toast.success("Deposit successfully");
                                presaleContext.refetchAllowance();
                            } else {
                                toast.error("Error! Deposit is failed.");
                            }

                        } else {
                            toast.error("Error! Approving & Depositing is failed.");
                        }
                    } else {
                        toast.error(`Deposit amount must be greater than ${minAmount} !`);
                    }
                } else {
                    toast.error(`Deposit amount must be smaller than ${presaleContext.balance / 1000000} !`);
                }
            } else {
                toast.error("Connect Wallet First!");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }
        setApprovePending(false);
    }

    const onDeposit = async () => {
        presaleContext.refetchBalance();
        // console.log("onDeposit", address, presaleContext.roundCount, Number(depositAmount), minAmount);
        setDepositPending(true);
        try {
            if (address) {
                if (presaleContext.roundCount != 0) {
                    if (Number(depositAmount) * 1000000 <= presaleContext.balance) {
                        if (Number(depositAmount) * 1000000 <= presaleContext.allowance) {
                            if (Number(depositAmount) >= minAmount) {
                                // console.log("pre executeDeposit", presaleContext.roundCount - 1, depositAmount * 1000000, presaleContext.referrer ? presaleContext.referrer : EMPTY_ADDRESS);
                                // const resultDeposit = await presaleContext.executeDeposit({
                                //     args: [presaleContext.roundCount - 1, depositAmount * 1000000, presaleContext.referrer ? presaleContext.referrer : EMPTY_ADDRESS],
                                //     from: address
                                // });
                                const resultDeposit = await presaleContext.executeDeposit(
                                    [presaleContext.roundCount - 1, depositAmount * 1000000, presaleContext.referrer ? presaleContext.referrer : EMPTY_ADDRESS],
                                    address
                                );
                                // console.log("resultDeposit", resultDeposit)
                                const txData = await waitForTransaction({ hash: resultDeposit });
                                // console.log("resultDeposit txData", txData)
                                if (txData && txData.status === "success") {
                                    // console.log("executeDeposit:", resultDeposit);
                                    toast.success("Deposit successfully");
                                    presaleContext.refetchAllowance();
                                } else {
                                    toast.error("Error! Deposit is failed.");
                                }
                            } else {
                                toast.error(`Deposit amount must be greater than ${minAmount} !`);
                            }
                        } else {
                            toast.error(`Deposit amount must be smaller than ${presaleContext.allowance / 1000000} !`);
                        }
                    } else {
                        toast.error(`Deposit amount must be smaller than ${presaleContext.balance / 1000000} !`);
                    }
                } else {
                    toast.error("No round!");
                }
            } else {
                toast.error("Connect Wallet First!");
            }
        } catch (error) {
            console.log(error.message);
            toast.error(error.message);
        }
        setDepositPending(false);
    }

    const onClaim = async () => {
        setClaimPending(true);
        try {
            if (address) {
                if (presaleContext.roundCount != 0) {
                    // const resultClaim = await presaleContext.executeClaim({
                    //     args: [presaleContext.roundCount - 1],
                    //     from: address
                    // });
                    const resultClaim = await presaleContext.executeClaim(
                        [presaleContext.roundCount - 1],
                        address
                    );
                    const txData = await waitForTransaction({ hash: resultClaim });
                    if (txData && txData.status === "success") {
                        toast.success("Claim successfully");
                    } else {
                        toast.error("Error! Claiming is failed.");
                    }
                } else {
                    toast.error("No round!");
                }
            } else {
                toast.error("Connect Wallet First!");
            }
        } catch (error) {
            toast.error(error.message);
        }
        setClaimPending(false);
    }

    return (
        <div className="font-helvetica relative w-[100vw] flex flex-col bg-[#fff] ">
            <div className="lg:h-[100vh] flex flex-col gap-[40px] justify-center items-center pt-[160px] px-[40px] lg:px-[120px] pb-[40px]">
                <div className="w-full flex flex-col lg:flex-row gap-[40px] justify-between">
                    <div className="w-full flex flex-col gap-[20px] justify-center items-center bg-[#e6e7e9] rounded-md shadow-sm px-[30px] py-[60px]">
                        <span className="text-[20px] lg:text-[30px] lg:text-[40px] font-bold text-[#000]">Public Sale</span>
                        <div className="flex flex-col lg:flex-row gap-[20px] items-center">
                            <span className="text-[20px] lg:text-[30px] text-[#000]">Balance: {balance}</span>
                            {presaleContext.allowance ? <span className="text-[20px] lg:text-[30px] text-[#000]">Approved: {presaleContext.allowance / 1000000}</span> : <></>}
                        </div>
                        <div className="flex flex-col md:flex-row gap-[20px] items-center">
                            <div className="w-full flex gap-[20px] items-center">
                                <input type="number" onChange={(e) => setDepositAmount(e.target.value)} value={depositAmount} placeholder="0" className="no-arrows w-full text-[20px] lg:text-[30px] bold-bold text-[#000] focus:outline-none bg-transparent px-[10px] border-2 border-[#000] rounded-md" />
                                <button onClick={(e) => setDepositAmount((presaleContext.allowance / 1000000).toString())} className="text-[20px] font-bold text-[#000] bg-transparent">MAX</button>
                                <img src="/images/tether.png" alt="tether" className="w-[50px] h-[50px] object-contain" />
                            </div>
                            {presaleContext.allowance == 0 ?
                                <button disabled={approvePending || depositPending || claimPending} onClick={(e) => onApproveAndDeposit()} className="text-[20px] font-bold text-[#fff] bg-[#00f] px-[20px] py-[5px] rounded-md shadow-sm">Approve & Deposit {approvePending ? <FontAwesomeIcon icon={faSpinner} size="sm" className="animate-spin" /> : <></>}</button> :
                                <button disabled={approvePending || depositPending || claimPending} onClick={(e) => onDeposit()} className="text-[20px] font-bold text-[#fff] bg-[#00f] px-[20px] py-[5px] rounded-md shadow-sm">Deposit {depositPending ? <FontAwesomeIcon icon={faSpinner} size="sm" className="animate-spin" /> : <></>}</button>}
                        </div>
                    </div>
                    <div className="w-full flex flex-col gap-[20px] justify-center items-center bg-[#e6e7e9] rounded-md shadow-sm px-[30px] py-[60px]">
                        <span className="text-[20px] md:text-[30px] lg:text-[40px] font-bold text-[#000]">My Balance</span>
                        <span className="text-[20px] lg:text-[30px] text-[#000]">{depositedAmount / tokenPrice} {schSymbol}</span>
                        {/* <button disabled={approvePending || depositPending || claimPending} onClick={(e) => onClaim()} className="text-[20px] font-bold text-[#fff] bg-[#00f] px-[20px] py-[5px] rounded-md shadow-sm">Claim {schSymbol} {claimPending ? <FontAwesomeIcon icon={faSpinner} size="sm" className="animate-spin" /> : <></>}</button> */}
                        <button disabled onClick={(e) => onClaim()} className="text-[20px] font-bold text-[#fff] bg-[#00f9] px-[20px] py-[5px] rounded-md shadow-sm">Claim {schSymbol} {claimPending ? <FontAwesomeIcon icon={faSpinner} size="sm" className="animate-spin" /> : <></>}</button>
                    </div>
                </div>
                <div className="w-full flex flex-row justify-center">
                    <div className="w-full lg:w-1/2 flex flex-col gap-[20px] justify-center items-center bg-[#e6e7e9] rounded-md shadow-sm px-[30px] py-[60px]">
                        <span className="text-[20px] md:text-[30px] lg:text-[40px] font-bold text-[#000]">Referral Link</span>
                        {address && <span className="text-[20px] lg:text-[30px] text-[#000]">/?ref={address.slice(0, 4)}...{address.slice(address.length - 4)}</span>}
                        <CopyToClipboard text={`${BASIC_URL}?ref=${address}`}>
                            <button className="text-[20px] font-bold text-[#fff] bg-[#00f] px-[20px] py-[5px] rounded-md shadow-sm">Copy To Clipboard</button>
                        </CopyToClipboard>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Buynow;