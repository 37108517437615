import React, { useEffect, useState, useContext } from "react";

import { PresaleContext } from "../context/presale-context";

const Home = () => {
  const presaleContext = useContext(PresaleContext);

  const [timeEnd, setTimeEnd] = useState(0);
  const [tokenSymbol, setTokenSymbol] = useState("SCH$");
  const [tokenSupply, setTokenSupply] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [remainingTokens, setRemainingTokens] = useState(0);

  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    const timerID = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  useEffect(() => {
    // console.log("referrer", presaleContext.referrer);
    // console.log("roundCount", presaleContext.roundCount);
    // console.log("schAddress", presaleContext.schAddress);
    // console.log("saleAddress", presaleContext.saleAddress);
    // console.log("stages", presaleContext.stages);
    // console.log("userDeposited", presaleContext.userDeposited);
    // console.log("userClaimed", presaleContext.userClaimed);
    // console.log("userLastClaimed", presaleContext.userLastClaimed);
    // console.log("affiliates", presaleContext.affiliates);
    // console.log("allowance", presaleContext.allowance);
    // console.log("balance", presaleContext.balance);
    // console.log("isLoading", presaleContext.isLoading);

    if (
      !presaleContext.isLoading &&
      presaleContext.stages.length != 0 &&
      presaleContext.roundCount != 0
    ) {
      const timeEnd =
        presaleContext.stages[presaleContext.roundCount - 1].timeToEnd;
      setTimeEnd(timeEnd);

      const tokenSupply =
        presaleContext.stages[presaleContext.roundCount - 1].amount;
      setTokenSupply(tokenSupply);

      const remainingTokens =
        presaleContext.stages[presaleContext.roundCount - 1].amount -
        presaleContext.stages[presaleContext.roundCount - 1].totalSale /
          1000000;
      setRemainingTokens(remainingTokens.toFixed(2));

      const tokenPrice =
        presaleContext.stages[presaleContext.roundCount - 1].price / 10000;
      setTokenPrice(tokenPrice);
    }
  }, [presaleContext]);

  return (
    <div className="font-helvetica relative w-[100vw] flex flex-col bg-[#fff] ">
      <div className="lg:h-[100vh] flex flex-col lg:flex-row gap-[40px] justify-start lg:justify-between items-center pt-[160px] px-[10px] md:px-[60px] lg:px-[120px] pb-[40px]">
        <img
          className="w-full lg:w-1/2 object-contain shadow-sm rounded-md"
          src="/images/bg3.png"
          alt="bg3"
        />
        <div className="w-full lg:h-full flex flex-col gap-[40px] lg:justify-between">
          <span className="w-full text-[40px] lg:text-[60px] font-bold text-[#000] text-center lg:text-left text-wrap leading-[45px] lg:leading-[65px]">
            Water is the driving force of all nature.
          </span>
          {/* <div className="flex justify-end">
            <div className="flex bg-[#00f] text-white p-2 rounded-md">
              <a href="https://www.schoepf.company" target="_blank">
              SCHÖPF Company
              </a>
            </div>
          </div> */}
          <div className="flex flex-col gap-[20px] bg-[#e6e7e9] rounded-md shadow-sm p-[20px] md:p-[40px]">
            <div className="flex flex-col">
              <span className="text-[20px] md:text-[30px] font-bold text-[#000]">
                Schöpf (SCH$) Token
              </span>
              <span className="text-[20px] md:text-[30px] font-bold text-[#000]">
                Presale ends in
              </span>
            </div>
            <div className="flex gap-[10px] md:gap-[20px] justify-between">
              <div className="flex flex-col gap-[10px] items-center">
                <span className="text-[50px] md:text-[60px] font-bold text-[#00f] leading-[80px] border-y border-y-[#000] ">
                  {Math.floor(
                    (timeEnd - currentTime / 1000 < 0
                      ? 0
                      : timeEnd - currentTime / 1000) / 86400
                  ) /
                    10 <
                  1
                    ? "0"
                    : ""}
                  {Math.floor(
                    (timeEnd - currentTime / 1000 < 0
                      ? 0
                      : timeEnd - currentTime / 1000) / 86400
                  )}
                </span>
                <span className="text-[12px] md:text-[14px] text-[#000]">
                  Days
                </span>
              </div>
              <div className="flex flex-col gap-[10px] items-center">
                <span className="text-[50px] md:text-[60px] font-bold text-[#00f] leading-[80px] border-y border-y-[#000] ">
                  {Math.floor(
                    ((timeEnd - currentTime / 1000 < 0
                      ? 0
                      : timeEnd - currentTime / 1000) %
                      86400) /
                      3600
                  ) /
                    10 <
                  1
                    ? "0"
                    : ""}
                  {Math.floor(
                    ((timeEnd - currentTime / 1000 < 0
                      ? 0
                      : timeEnd - currentTime / 1000) %
                      86400) /
                      3600
                  )}
                </span>
                <span className="text-[12px] md:text-[14px] text-[#000]">
                  Hours
                </span>
              </div>
              <div className="flex flex-col gap-[10px] items-center">
                <span className="text-[50px] md:text-[60px] font-bold text-[#00f] leading-[80px] border-y border-y-[#000] ">
                  {Math.floor(
                    ((timeEnd - currentTime / 1000 < 0
                      ? 0
                      : timeEnd - currentTime / 1000) %
                      3600) /
                      60
                  ) /
                    10 <
                  1
                    ? "0"
                    : ""}
                  {Math.floor(
                    ((timeEnd - currentTime / 1000 < 0
                      ? 0
                      : timeEnd - currentTime / 1000) %
                      3600) /
                      60
                  )}
                </span>
                <span className="text-[12px] md:text-[14px] text-[#000]">
                  Minutes
                </span>
              </div>
              <div className="flex flex-col gap-[10px] items-center">
                <span className="text-[50px] md:text-[60px] font-bold text-[#00f] leading-[80px] border-y border-y-[#000] ">
                  {Math.floor(
                    (timeEnd - currentTime / 1000 < 0
                      ? 0
                      : timeEnd - currentTime / 1000) % 60
                  ) /
                    10 <
                  1
                    ? "0"
                    : ""}
                  {Math.floor(
                    (timeEnd - currentTime / 1000 < 0
                      ? 0
                      : timeEnd - currentTime / 1000) % 60
                  )}
                </span>
                <span className="text-[12px] md:text-[14px] text-[#000]">
                  Seconds
                </span>
              </div>
            </div>
            <div className="flex flex-col border-t border-t-[#000]">
              <div className="flex justify-between items-center py-[10px] border-b border-b-[#000]">
                <span className="text-[16px] md:text-[20px] font-bold text-[#000]">
                  Token Name
                </span>
                <span className="text-[16px] md:text-[20px] font-bold text-[#000]">
                  {tokenSymbol}
                </span>
              </div>
              <div className="flex justify-between items-center py-[10px] border-b border-b-[#000]">
                <span className="text-[16px] md:text-[20px] font-bold text-[#000]">
                  Token Supply
                </span>
                <span className="text-[16px] md:text-[20px] font-bold text-[#000]">
                  {tokenSupply}
                </span>
              </div>
              <div className="flex justify-between items-center py-[10px] border-b border-b-[#000]">
                <span className="text-[16px] md:text-[20px] font-bold text-[#000]">
                  Remaining Tokens
                </span>
                <span className="text-[16px] md:text-[20px] font-bold text-[#000]">
                  {remainingTokens}
                </span>
              </div>
              <div className="flex justify-between items-center py-[10px] border-b border-b-[#000]">
                <span className="text-[16px] md:text-[20px] font-bold text-[#000]">
                  Presale Price
                </span>
                <span className="text-[16px] md:text-[20px] font-bold text-[#000]">
                  ${tokenPrice}
                </span>
              </div>
              <div className="flex justify-between items-center py-[10px] border-b border-b-[#000]">
                <span className="text-[16px] md:text-[20px] font-bold text-[#000]">
                  Accepted
                </span>
                <div className="flex gap-[40px] items-center">
                  <a href="/">
                    <img
                      className="w-[20px] md:w-[25px] h-[20px] md:h-[25px] object-contain"
                      src="/images/symbol1.png"
                      alt="symbol1"
                    />
                  </a>
                  <a href="/">
                    <img
                      className="w-[20px] md:w-[25px] h-[20px] md:h-[25px] object-contain"
                      src="/images/symbol2.png"
                      alt="symbol2"
                    />
                  </a>
                  <a href="/">
                    <img
                      className="w-[20px] md:w-[25px] h-[20px] md:h-[25px] object-contain"
                      src="/images/symbol3.png"
                      alt="symbol3"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
