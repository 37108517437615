import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { useAccount } from 'wagmi';
import { waitForTransaction } from "@wagmi/core";
import { parseEther, formatUnits } from 'viem';
import { PresaleContext } from "../context/presale-context";

import { toast } from "react-toastify";

const Dashboard = () => {

    const navigate = useNavigate();

    const presaleContext = useContext(PresaleContext);

    const { address } = useAccount();

    const [purchased, setPurchased] = useState(0);
    const [availableToWithdraw, setAvailableToWithdraw] = useState(0);
    const [availableBalance, setAvailableBalance] = useState(0);
    const [initialRelease, setInitialRelease] = useState(0);
    const [initialReleasePercentage, setInitialReleasePercentage] = useState(0);
    const [lockingPeriod, setLockingPeriod] = useState(30);
    const [linearVestingDate, setLinearVestingDate] = useState(Date.now() / 1000);

    const [searchWord, setSearchWord] = useState("");

    useEffect(() => {
        if (!address) {
            toast.error("Connect Wallet First!");
            navigate(`/${presaleContext.referral ? `?ref=${presaleContext.referral}` : ""}`)
        } else {
            if (!presaleContext.isLoading && presaleContext.stages.length != 0 && presaleContext.roundCount != 0) {
                const purchased = presaleContext.userDeposited[presaleContext.roundCount - 1];
                setPurchased(purchased);
                const availableToWithdraw = presaleContext.userDeposited[presaleContext.roundCount - 1] - presaleContext.userClaimed[presaleContext.roundCount - 1];
                setAvailableToWithdraw(availableToWithdraw)
                const availableBalance = purchased - availableToWithdraw;
                setAvailableBalance(availableBalance);
                const initialRelease = presaleContext.userDeposited[presaleContext.roundCount - 1] / presaleContext.stages[presaleContext.roundCount - 1].vestingPeriod;
                setInitialRelease(initialRelease);
                setInitialReleasePercentage((100 / presaleContext.stages[presaleContext.roundCount - 1].vestingPeriod).toFixed(1));
                const linearVestingDate = presaleContext.stages[presaleContext.roundCount - 1].timeToEnd + 86400 * 30;
                setLinearVestingDate(linearVestingDate);
            }
        }
    }, [address, presaleContext])

    return (
        <div className="font-helvetica relative w-[100vw] flex flex-col bg-[#fff] ">
            <div className="lg:h-[100vh] flex flex-col gap-[40px] justify-start lg:justify-between items-center pt-[160px] px-[40px] lg:px-[120px] pb-[40px]">
                <div className="w-full flex flex-col lg:flex-row gap-[40px] justify-between">
                    <div className="w-full flex flex-col justify-center items-center bg-[#e6e7e9] rounded-md shadow-sm px-[30px] py-[60px]">
                        <span className="text-[20px] lg:text-[30px] font-bold text-[#000] text-center">Purchased</span>
                        <span className="text-[40px] lg:text-[60px] font-bold text-[#00f]">{purchased / 1000000}</span>
                    </div>
                    <div className="w-full flex flex-col justify-center items-center bg-[#e6e7e9] rounded-md shadow-sm px-[30px] py-[60px]">
                        <span className="text-[20px] lg:text-[30px] font-bold text-[#000] text-center">Available Balance</span>
                        <span className="text-[40px] lg:text-[60px] font-bold text-[#00f]">{availableBalance / 1000000}</span>
                    </div>
                    <div className="w-full flex flex-col justify-center items-center bg-[#e6e7e9] rounded-md shadow-sm px-[30px] py-[60px]">
                        <span className="text-[20px] lg:text-[30px] font-bold text-[#000] text-center">Available To Withdraw</span>
                        <span className="text-[40px] lg:text-[60px] font-bold text-[#00f]">{availableToWithdraw / 1000000}</span>
                    </div>
                </div>
                <div className="w-full flex flex-col lg:flex-row gap-[40px] justify-between">
                    <div className="w-full flex flex-col gap-[40px] items-center bg-[#e6e7e9] rounded-md shadow-sm px-[30px] py-[30px]">
                        <span className="w-full text-[20px] lg:text-[30px] font-bold text-[#000]">Details</span>
                        <div className="w-full flex flex-col border-t border-t-[#000]">
                            <div className="flex justify-between items-center py-[10px] border-b border-b-[#000]">
                                <span className="text-[14px] lg:text-[16px] font-bold text-[#000]">Total Allocation</span>
                                <span className="text-[14px] lg:text-[16px] font-bold text-[#000]">{purchased / 1000000}</span>
                            </div>
                            <div className="flex justify-between items-center py-[10px] border-b border-b-[#000] has-tooltip">
                                <span className='tooltip rounded shadow-lg p-1 bg-gray-100 -mt-16 ml-24'>This means SCH token amount percentage allocated per Locking Period.</span>
                                <span className="text-[14px] lg:text-[16px] font-bold text-[#000]">Initial Release</span>
                                <span className="text-[14px] lg:text-[16px] font-bold text-[#000]">{initialReleasePercentage}% ({initialRelease / 1000000})</span>
                            </div>
                            <div className="flex justify-between items-center py-[10px] border-b border-b-[#000] has-tooltip">
                                <span className='tooltip rounded shadow-lg p-1 bg-gray-100 -mt-16 ml-28'>This represents Locking Period for staked SCH token due to each round.</span>
                                <span className="text-[14px] lg:text-[16px] font-bold text-[#000]">Locking Period</span>
                                <span className="text-[14px] lg:text-[16px] font-bold text-[#000]">{lockingPeriod} Days</span>
                            </div>
                            <div className="flex justify-between items-center py-[10px] border-b border-b-[#000] has-tooltip">
                                <span className='tooltip rounded shadow-lg p-1 bg-gray-100 -mt-16 ml-36'>This means claimable date for this round.</span>
                                <span className="text-[14px] lg:text-[16px] font-bold text-[#000]">Linear Vesting Date</span>
                                <span className="text-[14px] lg:text-[16px] font-bold text-[#000]">{(new Date(linearVestingDate * 1000)).toLocaleDateString()}</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-col gap-[10px] items-center bg-[#e6e7e9] rounded-md shadow-sm px-[30px] py-[30px]">
                        <div className="w-full flex flex-col lg:flex-row gap-[10px] justify-between lg:items-center">
                            <span className="text-[20px] lg:text-[30px] font-bold text-[#000]">Withdraw History</span>
                            <input onChange={(e) => setSearchWord(e.target.value)} value={searchWord} placeholder="SEARCH" className="text-[14px] text-[#000] px-[10px] py-[5px] bg-[#fff] focus:outline-none rounded-md" />
                        </div>
                        <div className="w-full hidden lg:flex flex-col">
                            <div className="flex flex-col lg:flex-row items-start lg:items-center py-[10px] pb-[10px] border-b border-b-[#000]">
                                <div className="w-full flex items-center">
                                    <span className="w-3/12 text-[14px] font-bold text-[#000]">No</span>
                                    <span className="w-9/12 text-[14px] font-bold text-[#000]">Transaction Hash</span>
                                </div>
                                <div className="w-full flex items-center">
                                    <span className="w-5/12 ltext-[14px] font-bold text-[#000]">Withdraw</span>
                                    <span className="w-7/12 text-[14px] font-bold text-[#000]">Date & Time</span>
                                </div>
                            </div>
                            <div className="max-h-[250px] overflow-y-auto w-full flex flex-col">
                                {presaleContext.claimHistory.map((claim, index) => {
                                    return (
                                        (!searchWord || claim._txHash.includes(searchWord)) && claim._user.toLowerCase() == address.toLowerCase() && <div className="flex flex-col lg:flex-row items-start lg:items-center py-[10px] pb-[10px] border-b border-b-[#000]">
                                            <div className="w-full flex items-center">
                                                <span className="w-3/12 text-[14px] text-[#000]">{index + 1}</span>
                                                <span className="w-9/12 text-[14px] text-[#000]">{claim._txHash.slice(0, 6)}...{claim._txHash.slice(claim._txHash.length - 6)}</span>
                                            </div>
                                            <div className="w-full flex items-center">
                                                <span className="w-5/12 text-[14px] text-[#000]">{Number(claim._amount) / 1000000}</span>
                                                <span className="w-7/12 text-[14px] text-[#000]">{(new Date(claim._timeStamp * 1000)).toLocaleString()}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="w-full flex lg:hidden overflow-x-auto">
                            <div className="flex flex-col">
                                <div className="flex py-[10px] pb-[10px] border-b border-b-[#000]">
                                    <span className="w-[100px] text-[14px] font-bold text-[#000]">No</span>
                                    <span className="w-[300px] text-[14px] font-bold text-[#000]">Transaction Hash</span>
                                    <span className="w-[100px] text-[14px] font-bold text-[#000]">Withdraw</span>
                                    <span className="w-[300px] text-[14px] font-bold text-[#000]">Date & Time</span>
                                </div>
                                <div className="max-h-[250px] overflow-y-auto w-full flex flex-col">
                                    {presaleContext.claimHistory.map((claim, index) => {
                                        return (
                                            (!searchWord || claim._txHash.includes(searchWord)) && address && claim._user.toLowerCase() == address.toLowerCase() && <div className="flex py-[10px] pb-[10px] border-b border-b-[#000]">
                                                <span className="w-[100px] text-[14px] text-[#000]">{index + 1}</span>
                                                <span className="w-[300px] text-[14px] text-[#000]">{claim._txHash.slice(0, 6)}...{claim._txHash.slice(claim._txHash.length - 6)}</span>
                                                <span className="w-[100px] text-[14px] text-[#000]">{Number(claim._amount) / 1000000}</span>
                                                <span className="w-[300px] text-[14px] text-[#000]">{(new Date(claim._timeStamp * 1000)).toLocaleString()}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;