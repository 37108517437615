import { useEffect, useState } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useDisconnect, useSwitchNetwork } from "wagmi";
import { CHAIN_INFO } from "../config";

/**
 * Custom Wagmi Connect Button (watch balance + custom design)
 */
export const CustomConnectButton = () => {
    const configuredNetwork = CHAIN_INFO;
    const { disconnect } = useDisconnect();
    const { switchNetwork } = useSwitchNetwork();

    return (
        <ConnectButton.Custom>
            {({ account, chain, openConnectModal, mounted }) => {
                const connected = mounted && account && chain;
                return (
                    <>
                        {(() => {
                            if (!connected) {
                                return (
                                    <div className="px-2 flex justify-end items-center">
                                        <button className="text-[20px] font-bold text-[#fff] bg-[#00f] px-[20px] py-[5px] rounded-md shadow-sm" onClick={openConnectModal} type="button">Connect Wallet</button>
                                    </div>
                                );
                            }

                            if (chain.unsupported || chain.id !== configuredNetwork.id) {
                                return (
                                    <button onClick={() => switchNetwork?.(configuredNetwork.id)} className="text-[20px] font-bold text-[#fff] bg-[#00f] px-[20px] py-[5px] rounded-md shadow-sm"><span className="whitespace-nowrap">Switch to <span className="text-[#ff8b9e]">{configuredNetwork.name}</span></span></button>
                                );
                            }

                            return (
                                <div className="px-2 flex justify-end items-center">
                                    <button className="text-[20px] font-bold text-[#fff] bg-[#00f] px-[20px] py-[5px] rounded-md shadow-sm" onClick={() => disconnect()}><span className="ml-2 mr-1">Disconnect {account.displayName}</span></button>
                                </div>
                            );
                        })()}
                    </>
                );
            }}
        </ConnectButton.Custom>
    );
};
