import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { useAccount } from 'wagmi';
import { waitForTransaction } from "@wagmi/core";
import { parseEther, formatUnits } from 'viem';
import { PresaleContext } from "../context/presale-context";
import { ADMIN_ADDRESS } from "../config";

import { toast } from "react-toastify";

const vestingPeriods = ['6 months', '5 months', '4 months', '3 months', '2 months', '1 months'];

const Admin = () => {

    const navigate = useNavigate();

    const presaleContext = useContext(PresaleContext);

    const { address } = useAccount();

    const [availableTokenBalance, setAvailableTokenBalance] = useState(0);
    const [totalTokenSale, setTotalTokenSale] = useState(0);
    const [availableToWithdraw, setAvailableToWithdraw] = useState(0);
    const [rounds, setRounds] = useState([]);

    const [startDateTime, setStartDateTime] = useState('');
    const [startDate, setStartDate] = useState('--/--/--');
    const [startTime, setStartTime] = useState('--:--:--');
    const [startTimestamp, setStartTimestamp] = useState(0);
    const [endDateTime, setEndDateTime] = useState('');
    const [endDate, setEndDate] = useState('--/--/--');
    const [endTime, setEndTime] = useState('--:--:--');
    const [endTimestamp, setEndTimestamp] = useState(0);

    const [vestingPeriodOpen, setVestingPeriodOpen] = useState(false);

    const [selectedVestingPeriod, setSelectedVestingPeriod] = useState(null);

    const [withdrawPending, setWithdrawPending] = useState(false);
    const [createRoundPending, setCreateRoundPending] = useState(false);

    const [searchWord, setSearchWord] = useState("");

    const handleStartDateTimeChange = (event) => {
        const value = event.target.value;
        setStartDateTime(value);

        if (value) {
            const [datePart, timePart] = value.split('T');
            setStartDate(datePart);
            setStartTime(timePart);
            const dateObj = new Date(value);
            setStartTimestamp(dateObj.getTime());
        } else {
            setStartDate('--/--/--');
            setStartTime('--:--:--');
            setStartTimestamp(null);
        }
    };

    const handleEndDateTimeChange = (event) => {
        const value = event.target.value;
        setEndDateTime(value);

        if (value) {
            const [datePart, timePart] = value.split('T');
            setEndDate(datePart);
            setEndTime(timePart);
            const dateObj = new Date(value);
            setEndTimestamp(dateObj.getTime());
        } else {
            setEndDate('--/--/--');
            setEndTime('--:--:--');
            setEndTimestamp(null);
        }
    };

    const toggleVestingPeriod = () => {
        setVestingPeriodOpen(!vestingPeriodOpen);
    };

    const handleItemClick = (item) => {
        setSelectedVestingPeriod(item);
        setVestingPeriodOpen(false);
    };

    useEffect(() => {

        if (!address) {
            toast.error("Connect Wallet First!");
            navigate(`/${presaleContext.referral ? `?ref=${presaleContext.referral}` : ""}`)
        }

        if (!presaleContext.isLoading && presaleContext.stages.length != 0 && presaleContext.roundCount != 0) {
            let totalTokenSale = 0;
            const rounds = presaleContext.stages.map((stage) => {
                totalTokenSale += stage.totalSale;
                return ({
                    startTime: stage.timeToStart,
                    endTime: stage.timeToEnd
                })
            })
            setRounds(rounds);
            setAvailableTokenBalance(presaleContext.presaleSchBalance);
            setTotalTokenSale(totalTokenSale);
            setAvailableToWithdraw(presaleContext.presaleSaleBalance);
        }
    }, [presaleContext])

    const onWithdraw = async () => {
        setWithdrawPending(true);
        try {
            if (address) {
                if (availableToWithdraw != 0) {
                    // const resultWithdraw = await presaleContext.executeWithdraw({
                    //     args: [],
                    //     from: address
                    // });
                    const resultWithdraw = await presaleContext.executeWithdraw(
                        [(rounds.length)-1, Number(availableToWithdraw)],
                        address
                    );
                    const txData = await waitForTransaction({ hash: resultWithdraw });
                    if (txData && txData.status === "success") {
                        toast.success("Withdraw successfully");
                        presaleContext.refetchAllowance();
                    } else {
                        toast.error("Error! Withdraw is failed.");
                    }
                } else {
                    toast.error("Nothing to withdraw!");
                }
            } else {
                toast.error("Connect Wallet First!");
            }
        } catch (error) {
            toast.error(error.message);
        }
        setWithdrawPending(false);
    }

    const onCreateRound = async () => {
        setCreateRoundPending(true);
        try {
            if (address) {
                if (startTimestamp != 0) {
                    if (endTimestamp != 0) {
                        if (selectedVestingPeriod) {
                            // const resultCreateRound = await presaleContext.executeCreateRound({
                            //     args: [Number((startTimestamp / 1000).toFixed(0)), Number((endTimestamp / 1000).toFixed(0)), Number((endTimestamp / 1000).toFixed(0)), 1, 3000, Number(selectedVestingPeriod.slice(0, 1))],
                            //     from: address
                            // });
                            const resultCreateRound = await presaleContext.executeCreateRound(
                                [Number((startTimestamp / 1000).toFixed(0)), Number((endTimestamp / 1000).toFixed(0)), Number((endTimestamp / 1000).toFixed(0)), 1, 3000, Number(selectedVestingPeriod.slice(0, 1))],
                                address
                            );
                            const txData = await waitForTransaction({ hash: resultCreateRound });
                            if (txData && txData.status === "success") {
                                toast.success("CreateRound successfully");
                                presaleContext.refetchPresaleSchBalance();
                                presaleContext.refetchPresaleSaleBalance();
                            } else {
                                toast.error("Error! CreateRound is failed.");
                            }
                        } else {
                            toast.error("Invalid Vesting Period!");
                        }
                    } else {
                        toast.error("Invalid End Date!");
                    }
                } else {
                    toast.error("Invalid Start Date!");
                }
            } else {
                toast.error("Connect Wallet First!");
            }
        } catch (error) {
            toast.error(error.message);
        }
        setCreateRoundPending(false);
    }

    return (
        <div className="font-helvetica relative w-[100vw] flex flex-col bg-[#fff] ">
            <div className="lg:h-[100vh] flex flex-col gap-[40px] justify-end lg:justify-between items-center pt-[160px] px-[40px] lg:px-[120px] pb-[40px]">
                <div className="w-full flex flex-col lg:flex-row gap-[40px] justify-between">
                    <div className="w-full flex flex-col justify-center items-center bg-[#e6e7e9] rounded-md shadow-sm px-[30px] py-[60px]">
                        <span className="text-[20px] lg:text-[30px] font-bold text-[#000] text-center">Available Token Balance</span>
                        <div className="flex gap-[20px] items-center">
                            <img src="/images/icon.png" alt="" className="w-[40px] h-[40px] objec-contain" />
                            <span className="text-[20px] lg:text-[30px] font-bold text-[#000]">{availableTokenBalance / 1000000000000000000}</span>
                        </div>
                    </div>
                    <div className="w-full flex flex-col justify-center items-center bg-[#e6e7e9] rounded-md shadow-sm px-[30px] py-[60px]">
                        <span className="text-[20px] lg:text-[30px] font-bold text-[#000] text-center">Total Token Sale</span>
                        <div className="flex gap-[20px] items-center">
                            <img src="/images/tether.png" alt="" className="w-[40px] h-[40px] objec-contain" />
                            <span className="text-[20px] lg:text-[30px] font-bold text-[#000]">{totalTokenSale / 1000000}</span>
                        </div>
                    </div>
                    <div className="w-full flex flex-col gap-[10px] justify-center items-center bg-[#e6e7e9] rounded-md shadow-sm px-[30px] py-[60px]">
                        <span className="text-[20px] lg:text-[30px] font-bold text-[#000] text-center">Available To Withdraw</span>
                        <div className="flex gap-[20px] items-center">
                            <img src="/images/tether.png" alt="" className="w-[40px] h-[40px] objec-contain" />
                            <span className="text-[20px] lg:text-[30px] font-bold text-[#000]">{availableToWithdraw / 1000000}</span>
                        </div>
                        <button onClick={(e) => onWithdraw()} className="text-[20px] font-bold text-[#fff] bg-[#00f] px-[20px] py-[5px] rounded-md shadow-sm">Withdraw</button>
                    </div>
                </div>
                <div className="w-full flex flex-col lg:flex-row gap-[40px] justify-between">
                    <div className="w-full flex flex-col gap-[10px] items-center bg-[#e6e7e9] rounded-md shadow-sm px-[30px] py-[30px]">
                        <div className="w-full flex flex-col lg:flex-row gap-[10px] justify-between lg:items-center">
                            <span className="text-[20px] lg:text-[30px] font-bold text-[#000]">Presale</span>
                            <input onChange={(e) => setSearchWord(e.target.value)} value={searchWord} placeholder="SEARCH" className="text-[14px] text-[#000] px-[10px] py-[5px] bg-[#fff] focus:outline-none rounded-md" />
                        </div>
                        <div className="w-full hidden lg:flex flex-col">
                            <div className="flex flex-col lg:flex-row items-start lg:items-center py-[10px] pb-[10px] border-b border-b-[#000]">
                                <div className="w-full flex items-center">
                                    <span className="w-4/12 text-[14px] font-bold text-[#000]">No</span>
                                    <span className="w-6/12 text-[14px] font-bold text-[#000]">Round</span>
                                </div>
                                <div className="w-full flex items-center">
                                    <span className="w-full text-[14px] font-bold text-[#000]">Start Time</span>
                                </div>
                                <div className="w-full flex items-center">
                                    <span className="w-full text-[14px] font-bold text-[#000]">End Time</span>
                                </div>
                            </div>
                            <div className="max-h-[300px] overflow-y-auto w-full flex flex-col">
                                {rounds.map((round, index) => {
                                    return (
                                        (!searchWord || (new Date(round.startTime * 1000).toLocaleDateString()).includes(searchWord) || (new Date(round.endTime * 1000).toLocaleDateString()).includes(searchWord)) && <div className="flex flex-col lg:flex-row items-start lg:items-center py-[10px] pb-[10px] border-b border-b-[#000]">
                                            <div className="w-full flex items-center">
                                                <span className="w-4/12 text-[14px] text-[#000]">{index + 1}</span>
                                                <span className="w-6/12 text-[14px] text-[#000]">Round {index + 1}</span>
                                            </div>
                                            <div className="w-full flex items-center">
                                                <span className="w-full text-[14px] text-[#000]">{(new Date(round.startTime * 1000).toLocaleDateString())}</span>
                                            </div>
                                            <div className="w-full flex items-center">
                                                <span className="w-full text-[14px] text-[#000]">{(new Date(round.endTime * 1000).toLocaleDateString())}</span>
                                            </div>
                                        </div>
                                    )
                                }
                                )}
                            </div>
                        </div>
                        <div className="w-full flex lg:hidden overflow-x-auto">
                            <div className="flex flex-col">
                                <div className="flex py-[10px] pb-[10px] border-b border-b-[#000]">
                                    <span className="w-[100px] text-[14px] font-bold text-[#000]">No</span>
                                    <span className="w-[100px] text-[14px] font-bold text-[#000]">Round</span>
                                    <span className="w-[300px] text-[14px] font-bold text-[#000]">Start Time</span>
                                    <span className="w-[300px] text-[14px] font-bold text-[#000]">End Time</span>
                                </div>
                                <div className="max-h-[300px] overflow-y-auto w-full flex flex-col">
                                    {rounds.map((round, index) => {
                                        return (
                                            (!searchWord || (new Date(round.startTime * 1000).toLocaleDateString()).includes(searchWord) || (new Date(round.endTime * 1000).toLocaleDateString()).includes(searchWord)) && <div className="flex py-[10px] pb-[10px] border-b border-b-[#000]">
                                                <span className="w-[100px] text-[14px] text-[#000]">{index + 1}</span>
                                                <span className="w-[100px] text-[14px] text-[#000]">Round {index + 1}</span>
                                                <span className="w-[300px] text-[14px] text-[#000]">{(new Date(round.startTime * 1000).toLocaleDateString())}</span>
                                                <span className="w-[300px] text-[14px] text-[#000]">{(new Date(round.endTime * 1000).toLocaleDateString())}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg::max-w-[300px] flex flex-col gap-[20px] items-center bg-[#e6e7e9] rounded-md shadow-sm px-[30px] py-[30px]">
                        <span className="w-full text-[20px] lg:text-[30px] font-bold text-[#000] text-center">ICO Schedule</span>
                        <div className="w-full flex flex-col gap-[20px]">
                            <div className="w-full flex flex-col">
                                <span className="text-[14px] lg:text-[18px] font-bold text-[#000]">ICO Start Date</span>
                                <input value={startDateTime} onChange={handleStartDateTimeChange} className="border border-[#000] bg-transparent px-[10px] rounded-md shadow-sm" aria-label="Date and time" type="datetime-local" />
                            </div>
                            <div className="w-full flex flex-col">
                                <span className="text-[14px] lg:text-[18px] font-bold text-[#000]">ICO End Date</span>
                                <input value={endDateTime} onChange={handleEndDateTimeChange} className="border border-[#000] bg-transparent px-[10px] rounded-md shadow-sm" aria-label="Date and time" type="datetime-local" />
                            </div>
                            <div className="w-full flex flex-col">
                                <span className="text-[14px] lg:text-[18px] font-bold text-[#000]">Presale Date</span>
                                <div className="flex gap-[10px]">
                                    <span className="text-[16px] text-[#000]">{startDate}</span>
                                    <span className="text-[16px] text-[#000]">~</span>
                                    <span className="text-[16px] text-[#000]">{endDate}</span>
                                </div>
                            </div>
                            <div className="w-full flex flex-col">
                                <span className="text-[14px] lg:text-[18px] font-bold text-[#000]">Vesting Period</span>
                                <div className="relative inline-block text-left">
                                    <div>
                                        <button
                                            type="button"
                                            className="inline-flex justify-between w-full rounded-md border border-[#000] shadow-sm px-[10px] py-[5px] text-sm font-medium text-[#000] bg-transparent focus:outline-none"
                                            onClick={toggleVestingPeriod}
                                        >
                                            <div className="w-[20px]"> </div>
                                            {selectedVestingPeriod ? selectedVestingPeriod : 'Select an option'}
                                            <svg
                                                className="-mr-1 ml-2 h-5 w-5"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.172l3.71-3.94a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    {vestingPeriodOpen && (
                                        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white focus:outline-none">
                                            <div className="py-1">
                                                {vestingPeriods.map((item, index) => (
                                                    <button
                                                        key={index}
                                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                                        onClick={() => handleItemClick(item)}
                                                    >
                                                        {item}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <button onClick={(e) => onCreateRound()} className="text-[20px] font-bold text-[#fff] bg-[#00f] px-[20px] py-[5px] rounded-md shadow-sm">Create Round</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Admin;