// export const CHAIN_INFO = { id: 11155111, name: "Sepolia" };
export const CHAIN_INFO = { id: 1, name: "Mainnet" };

// export const SEPOLIA_SCHOPF_PRESALE_ADDRESS = "0xc2a783aaF2855bb3F1A0A3202c99de830c1544a1";
// export const MAINNET_SCHOPF_PRESALE_ADDRESS = "0xa23cb9c5c020706737792c8925f039a5315a2e27";
// export const MAINNET_SCHOPF_PRESALE_ADDRESS = "0x1fb6c2De1CaF8634Cd9395Ff0b9410c68834bBD6";
// export const MAINNET_SCHOPF_PRESALE_ADDRESS = "0x63b6b863d6E9A19783b9161E7C2B7FE912662eff";
export const MAINNET_SCHOPF_PRESALE_ADDRESS = "0x3a6bb57A421Ae7017e46f3CAB09c47108dEa36C1";

export const ADMIN_ADDRESS = "0xF528Af3b4b88C211F13b4B8D4c22576d76A81269";

export const EMPTY_ADDRESS = "0x0000000000000000000000000000000000000000";

export const BASIC_URL = "https://schoepf.io";

export const THE_GRAPH_URL =
  // "https://api.studio.thegraph.com/query/65018/schopf_1/version/latest";
  "https://api.studio.thegraph.com/query/65018/schopf_mainnet/version/latest";

export const claimHistoryQuery = `
query {
  claims(first: 5) {
    id
    _user
    _stage
    _amount
    _txHash
    _timeStamp
    blockNumber
    blockTimestamp
    transactionHash
  }
}
`;